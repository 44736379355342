<template>
  <div
    data-page-container-background-image
    class="absolute inset-0 overflow-hidden bg-cover"
    :class="{
      'before:from-light before:dark:from-dark before:absolute before:top-0 before:h-1/2 before:w-full before:bg-gradient-to-b before:to-transparent before:content-[\'\']':
        backgroundGradientTop,
      'after:to-light after:dark:to-dark after:absolute after:bottom-0 after:h-1/2 after:w-full after:bg-gradient-to-b after:from-transparent after:content-[\'\']':
        backgroundGradientBottom,
      'bg-top': backgroundImagePosition === 'top',
      'bg-center': backgroundImagePosition === 'center',
      'bg-bottom': backgroundImagePosition === 'bottom',
    }"
    :style="`
      background-image: url('${imgUrl}'), linear-gradient(-60deg, ${gradientColors});
    `"
  ></div>
</template>

<script setup lang="ts">
import type { Image, ImageModifier } from '#gql/default'

interface Props {
  backgroundImage: Image
  backgroundImagePosition: string
  backgroundImageModifiers?: ImageModifier[]
  backgroundGradientBottom?: boolean
  backgroundGradientTop?: boolean
  preload: boolean
}

const props = defineProps<Props>()

const img = useImage()

function processImageUrl(imageUrl: string): {
  url: string
  modifiers: Record<string, string>
} {
  const originalUrl = new URL(imageUrl)
  const url = originalUrl.origin + originalUrl.pathname

  const modifiers: Record<string, string> = {}
  const params = new URLSearchParams(originalUrl.search)

  params.forEach((value, key) => {
    function isAllowedKey(key: string): key is keyof typeof IMAGE_MODIFIER {
      return Object.values(IMAGE_MODIFIER).includes(key as any)
    }

    if (isAllowedKey(key)) {
      modifiers[key] = value
    }
  })

  return { url, modifiers }
}

const { src, width, height, colors } = getImage(props, 'backgroundImage', '')

const gradientColors = computed(() =>
  colors?.length ? colors.join(',') : ['#000', '#000'],
)

const imgUrl = computed(() => {
  if (!src || !width || !height) {
    return ''
  }

  const { url, modifiers } = processImageUrl(src)

  const maxWidth = 1920
  const originalWidth = width
  const originalHeight = height
  const aspectRatio = originalHeight / originalWidth
  const widthFallback = Math.min(originalWidth, maxWidth)
  const heightFallback = Math.round(widthFallback * aspectRatio)

  // Convert backgroundImageModifiers array to an object
  const modifiersFromProps =
    props.backgroundImageModifiers?.reduce<Record<string, any>>(
      (acc, { key, value }) => {
        acc[key] = value
        return acc
      },
      {},
    ) || {}

  return img(url, {
    width: modifiersFromProps.width || widthFallback,
    height: modifiersFromProps.height || heightFallback,
    quality: 80,
    format: 'webp',
    ...modifiers,
    ...modifiersFromProps,
  })
})

// preload image
useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      href: imgUrl,
    },
  ],
})

defineOptions({
  name: 'PageContainerBackground',
})
</script>
