export const CMS_CONFIG_SCOPE = {
  CINEMAS: 'Cinemas',
  PORTAL: 'Portal',
}

export const CMS_HTML_BLOCK_FORMAT_SIZE = {
  SM: 'sm',
  BASE: 'base',
  LG: 'lg',
  XL: 'xl',
  XXL: '2xl',
} as const

export const CMS_IMAGE_BLOCK_RATIO = {
  ORIGINAL: 'original',
  RATIO_1_1: '1:1',
  RATIO_3_4: '3:4',
  RATIO_16_9: '16:9',
  RATIO_4_1: '4:1',
} as const

export const CMS_IMAGE_BLOCK_SIZE = {
  THUMBNAIL: 'thumbnail',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  BOXED: 'boxed',
  ORIGINAL: 'original',
} as const

export const CMS_IMAGE_BLOCK_ALIGNMENT = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const

export const CMS_DIVIDER_HORIZONTAL_SPACING = {
  NONE: 'none',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const

export const CMS_DIVIDER_VERTICAL_SPACING = {
  NONE: 'none',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const

export const CMS_DIVIDER_SEPARATOR_STYLE = {
  NONE: 'none',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export const CMS_DIVIDER_LAYOUT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  NEUTRAL: 'neutral',
} as const

export const CMS_TABLE_LAYOUT = {
  SIMPLE: 'simple',
  VERTICAL_LINES: 'vertical-lines',
  STRIPES: 'stripes',
} as const

export const CMS_TABLE_CAPTION_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const

export const DEFAULT_GAP = {
  default: PAGE_GRID_GAP_SIZE.SM,
  sm: PAGE_GRID_GAP_SIZE.SM,
  md: PAGE_GRID_GAP_SIZE.SM,
  lg: PAGE_GRID_GAP_SIZE.SM,
} as const

export const DEFAULT_LAYOUT = {
  sm: PAGE_GRID_LAYOUT.COLS_1,
  md: PAGE_GRID_LAYOUT.COLS_1,
  lg: PAGE_GRID_LAYOUT.COLS_1,
} as const
