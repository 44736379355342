<script setup lang="ts">
import type { CmsBlock } from '#gql/default'

interface Props {
  block: CmsBlock
  renderAboveTheFold?: boolean
}

const { block, renderAboveTheFold = false } = defineProps<Props>()

const cmsBlockComponent = computed(() => {
  const cmsBlockComponents = {
    CmsGalleryCarouselBlock: () => import('./gallery.vue'),
    CmsGalleryHorizontalBlock: () => import('./gallery.vue'),
    CmsGalleryVerticalBlock: () => import('./gallery.vue'),
    CmsImageBlock: () => import('./image.vue'),
    CmsVideoBlock: () => import('./video.vue'),
    CmsHtmlBlock: () => import('./html.vue'),
    CmsMovieListBlock: () => import('./movie-list.vue'),
    CmsLegacyAccountWidgetBlock: () => import('./legacy-widget.vue'),
    CmsLegacyShopWidgetBlock: () => import('./legacy-widget.vue'),
    CmsMoviesCatalogBlock: () => import('./movies.vue'),
    CmsCinemasForMovieBlock: () => import('./cinemas-for-movie.vue'),
    CmsCinemasCatalogBlock: () => import('./cinemas.vue'),
    CmsNewsletterWidgetBlock: () => import('./newsletter.vue'),
    CmsEventimNewsletterWidgetBlock: () =>
      import('./eventim-newsletter.client.vue'),
    CmsProgramBlock: () => import('./program.vue'),
    CmsAdSlotBlock: () => import('./ad-slot.vue'),
    CmsFormBlock: () => import('./form.vue'),
    CmsDividerBlock: () => import('./divider.vue'),
    CmsGoogleMapsBlock: () => import('./google-maps.vue'),
    CmsTableBlock: () => import('./table.vue'),
    CmsVoucherShopBlock: () => import('./voucher-shop.vue'),
    CmsAdventCalendarBlock: () => import('./advent-calendar/index.vue'),
    CmsTabsBlock: () => import('./tabs.vue'),
  } as const

  return Object.fromEntries(
    Object.entries(cmsBlockComponents).map(([type, loader]) => [
      type,
      defineAsyncComponent(loader as () => Promise<{ default: Component }>),
    ]),
  ) as Record<CmsBlock, Component>
})

defineOptions({
  name: 'CmsBlockRenderer',
})
</script>

<template>
  <component
    :is="cmsBlockComponent[block.__typename]"
    :render-above-the-fold="renderAboveTheFold"
    v-bind="block"
  />
</template>
